<script setup>
import SiteInfoService from '@/services/SiteInfoService';
import { settingsStore } from '@/store/settingsStore';
import { siteInfoStore } from '@/store/siteInfoStore';
import { onMounted } from 'vue';
import AppFooter from '@/components/public/AppFooter.vue';
import SettingsService from '@/services/SettingsService';
import AppHeader from '@/components/public/AppHeader.vue';

onMounted( async () => {
    await getSiteInfo();
})

const getSiteInfo = async () => {
    setInterval(async () => {
        const [siteInfoResponse, settingsResponse] = await Promise.all([
            SiteInfoService.getSiteInfo(),
            SettingsService.getAll()
        ]);

        siteInfoStore.updateConstants(siteInfoResponse.data);
        settingsStore.setSettings(settingsResponse.data);
        
        // if(settingsStore.getSetting('maintenance') === 'on') {
        //     const userLogged = AuthService.getUserLogged();

        //     if(userLogged && !allowedRoles.includes(userLogged.role)) {
        //         router.push(showText('PATH_MAINTENANCE'));
        //     }
        // }

    }, 120000); 
};
</script>

<template>
    <section class="publicLayout position-relative m-0 p-0">
        <AppHeader />
        <main>
            <router-view></router-view>
        </main>
        <AppFooter />
    </section>
</template>