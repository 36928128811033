<template>
    <section id="errorPage" class="container d-flex flex-column justify-content-center align-items-center vh-100 show">
        <img src="@/assets/svg/error.svg" alt="Error">
    </section>
</template>

<script setup>

</script>

<style scoped>
img, p {
    max-width: 500px;
}

p {
    max-width: 600px;
}
</style>