import { allowedRoles } from "@/helpers/constants";
import { showAlert } from "@/helpers/showAlert";
import PublicLayout from "@/layouts/PublicLayout.vue";
import AuthService from "@/services/AuthService";
import { loadingPageStore } from "@/store/loadingPageStore";
import { settingsStore } from "@/store/settingsStore";
import { showText } from "@/translation";
import LoginView from "@/views/public/auth/LoginView.vue";
import RegisterView from "@/views/public/auth/RegisterView.vue";
import BlogView from "@/views/public/BlogView.vue";
import ContactView from "@/views/public/ContactView.vue";
import EventsView from "@/views/public/EventsView.vue";
import HomeView from "@/views/public/homeView.vue";
import ListingsView from "@/views/public/ListingsView.vue";
import { createRouter, createWebHistory } from "vue-router";

let router = null;

export function initializeRoutes() {
    const routes = [
        {
            path: "/",
            component: PublicLayout,
            children: [
                {
                    path: "",
                    component: HomeView
                },
                {
                    path: showText("PUBLIC_PATH_CONTACT"),
                    component: ContactView
                },
                {
                    path: showText("PUBLIC_PATH_LISTINGS"),
                    component: ListingsView
                },
                {
                    path: showText("PUBLIC_PATH_EVENTS"),
                    component: EventsView
                },
                {
                    path: showText("PUBLIC_PATH_BLOG"),
                    component: BlogView
                },
                {
                    path: showText("PUBLIC_PATH_LOGIN"),
                    component: LoginView
                },
                {
                    path: showText("PUBLIC_PATH_REGISTER"),
                    component: RegisterView
                },
                {
                    path: showText("PUBLIC_PATH_USER_PANEL"),
                    component: () => import("@/views/public/user/PanelView.vue"),
                    meta: { requiresAuth: true }
                },
                {
                    path: showText("PUBLIC_PATH_USER_PROFILE"),
                    component: () => import("@/views/public/user/ProfileView.vue"),
                    meta: { requiresAuth: true }
                }
            ]
        },
        {
            path: showText('PUBLIC_PATH_MAINTENANCE'),
            component: () => import('@/views/public/MaintenanceView.vue'),
        },
        {
            path: showText("PUBLIC_PATH_PAGE_NOT_FOUND"),
            component: () => import("@/views/PageNotFoundView.vue")
        },
        {
            path: showText("ADMIN_PATH_LOGIN"),
            component: () => import("@/views/admin/loginView.vue"),
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: showText("PUBLIC_PATH_PAGE_NOT_FOUND")
        },
        {
            path: '/app/:pathMatch(.*)*',
            redirect: '/app'
        }
    ];

    router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
              return savedPosition;
            } else {
              return { top: 0 };
            }
        }
    });
    
    router.beforeEach((to, from, next) => {
        const { requiresAuth, requiresAuthAdmin } = to.meta;
        const isEnteringApp = to.path.startsWith('/app') && !from.path.startsWith('/app');
        const maintenanceMode = settingsStore.getSetting('maintenance') === "on";

        // Function that handles authentication and displays the loading screen
        const handleAuth = () => {
            loadingPageStore.show();
            return AuthService.auth()
                .finally(() => loadingPageStore.hide());
        };

        // Redirects to the maintenance page
        const redirectToMaintenance = () => {
            return next({ path: showText("PUBLIC_PATH_MAINTENANCE") });
        };

        // Redirects to the login page
        const redirectToLogin = () => {
            return next({
                path: showText("PUBLIC_PATH_LOGIN"),
                query: { redirect: to.path }
            });
        };

        // Redirects to the admin login page
        const redirectToLoginAdmin = () => {
            return next({ path: showText("ADMIN_PATH_LOGIN") });
        };

        // Checks if maintenance mode is enabled
        // Returns false if the user should be redirected to the maintenance page, interrupting navigation
        const checkMaintenanceMode = () => {
            const userLogged = AuthService.getUserLogged();
    
            // If the user is logged in and is a superuser, it does not enter maintenance mode
            if(userLogged && allowedRoles.includes(userLogged.role)) {
                return true;
            }
            
            // If maintenance mode is enabled and the user is not on allowed routes, redirect
            if (maintenanceMode && to.path !== showText("PUBLIC_PATH_MAINTENANCE") && !to.path.startsWith('/app') && to.path !== showText("ADMIN_PATH_LOGIN")) {
                redirectToMaintenance();
                return false;
            }
    
            return true;
        };

        // Checks if the user has the appropriate permission to access the route
        // Returns false and redirects to the home page if the user does not have permission
        const checkUserRole = (role) => {
            if (allowedRoles.includes(role)) {
                return true;
            } else {
                showAlert('error', '', showText("ACCESS_DENIED_MESSAGE"));
                redirectToLogin();
                return false;
            }
        };

        // Check maintenance mode before anything else
        if (!checkMaintenanceMode()) return;

        // Check if the route requires authentication
        if (requiresAuth) {
            handleAuth()
                .then(() => next())
                .catch(redirectToLogin);
        } else if (requiresAuthAdmin && isEnteringApp) {
            // Checks if the user is trying to enter the admin area and is authenticated
            handleAuth()
                .then(response => {
                    if (checkUserRole(response.data.role)) {
                        next();
                    }
                })
                .catch(redirectToLoginAdmin);
        } else {
            next();
        }
    });

    return router;  
}

export { router };
