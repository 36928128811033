import axios from '@/http';
import { showError } from '@/helpers/showError';

class SiteInfoService {
    async getSiteInfo() {
        try {
            return  await axios.get('/siteinfo');
        } catch (error) {
            showError(error);
            throw error;
        }
    }
}

export default new SiteInfoService();
