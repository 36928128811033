<script setup>
import BtnPrimary from '@/components/shared/buttons/BtnPrimary.vue';
import SEOManager from '@/helpers/SEOManager';
import { showAlert } from '@/helpers/showAlert';
import { router } from '@/router';
import AuthService from '@/services/AuthService';
import { siteInfoStore } from '@/store/siteInfoStore';
import { showText } from '@/translation';
import UserValidator from '@/validators/UserValidator';
import { onMounted, reactive, ref } from 'vue';

const isLoading = ref(false);

const formData = reactive({
    name: "",
    lastName: "",
    email: "",
    password: "",
});

const showPassword = ref(false);

onMounted(() => {
    const SEOTitle = siteInfoStore.constants.webSiteName ? `${showText('SEO_TITLE_REGISTER')} | ${siteInfoStore.constants.webSiteName}` : showText('SEO_TITLE_REGISTER');
    SEOManager.setTitle(SEOTitle);
})

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const isFieldEmpty = (event) => {
    const inputElement = event.target;

    if(!inputElement.value.trim()) {
        inputElement.classList.add("invalid-field");
    } else {
        inputElement.classList.remove('invalid-field');
    }
}

const submitForm = async () => {
    const requiredFields = {
        name: { label: showText("LABEL_NAME"), required: true},
        lastName: { label: showText("LABEL_LAST_NAME"), required: false},
        email: { label: showText("LABEL_EMAIL"), required: true},
        password: { label: showText("LABEL_PASSWORD"), required: true},
    }

    if(!UserValidator.validateForm(formData, requiredFields)) {
        return;
    }

    isLoading.value = true;

    try {
        const response = await AuthService.register(formData);
        isLoading.value = false;

        showAlert("success", "", response.data.message);

        router.push({
            path: showText("PUBLIC_PATH_LOGIN"),
            query: {email: response.data.email}
        });
    } catch (error) {
        isLoading.value = false;
        console.error('Error creating account', error);
    }
}
</script>

<template>
    <section class="registerView container d-flex align-items-center showPage">
        <div class="row w-100 m-0">
            <div class="col-sm-12 col-md-5 col-xl-5 d-flex flex-column justify-content-center align-items-center">
                <h3 class="text-secondary-emphasis">
                    {{ showText("CREATE_ACCOUNT_TITLE") }}
                </h3>

                <form @submit.prevent="submitForm()"  class="formRegister w-100 p-0 p-sm-3">
                    <div class="mb-3">
                        <label for="name" class="text-secondary mb-2"><span class="text-danger">*</span>
                            {{ showText("LABEL_NAME") }}
                        </label>
                        <input v-model="formData.name" @blur="isFieldEmpty($event)" type="text" name="name" id="name" autocomplete="name" class="form-control custom_focus">
                    </div>

                    <div class="mb-3">
                        <label for="lastName" class="text-secondary mb-2"><span class="text-danger">*</span>
                            {{ showText("LABEL_LAST_NAME") }}
                        </label>
                        <input v-model="formData.lastName" @blur="isFieldEmpty($event)" type="text" name="lastName" id="lastName" autocomplete="lastName" class="form-control custom_focus">
                    </div>

                    <div class="mb-3">
                        <label for="email" class="text-secondary mb-2"><span class="text-danger">*</span>
                            {{ showText("LABEL_EMAIL") }}
                        </label>
                        <input v-model="formData.email" @blur="isFieldEmpty($event)" type="email" name="email" id="email" autocomplete="email" class="form-control custom_focus">
                    </div>

                    <div class="mb-3">
                        <label for="password" class="text-secondary mb-2"><span class="text-danger">*</span>
                            {{ showText("LABEL_PASSWORD") }}
                        </label>
                        <div class="position-relative">
                            <input v-model="formData.password" @blur="isFieldEmpty($event)" :type="showPassword ? 'text' : 'password'" id="password" class="form-control custom_focus">
                            <i v-if="formData.password" @click="togglePasswordVisibility()" class="icon_show_password" :class="showPassword ? 'fa-regular fa-eye-slash text-secondary' : 'fa-regular fa-eye text-secondary'"></i>
                        </div>
                    </div>

                    <div class="fs-8 text-secondary text-center mb-3">
                        {{ showText("PRIVACY_MESSAGE") }}
                        <router-link :to="showText('PUBLIC_PATH_PRIVACY')" class="text-primary outline_none">
                            {{ showText("NAV_NAME_PRIVACY") }}
                        </router-link>
                    </div>

                    <BtnPrimary 
                        class="w-100"
                        :text="showText('BTN_TEXT_CREATE_ACCOUNT')"
                        :loading="isLoading"
                        type="submit"
                    />

                    <hr class="text-secondary">

                    <p class="text-secondary text-center">
                        {{ showText("HAVE_ACCOUNT_MESSAGE") }}
                        <router-link :to="showText('PUBLIC_PATH_LOGIN')" class="text-primary outline_none">
                            {{ showText("NAV_NAME_LOGIN") }}
                        </router-link>
                    </p>
                </form>
            </div>
            <div class="col-md-7 col-xl-7 d-none d-md-flex justify-content-center align-items-center">
                <img src="@/assets/svg/signUp.svg" alt="register">
            </div>
        </div>
    </section>
</template>

<style scoped>
.registerView {
    height: calc(100vh - 100px)
}

.formRegister {
    max-width: 400px;
}

.icon_show_password {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
}

img {
    max-width: 600px;
}
</style>