<script setup>
import SEOManager from '@/helpers/SEOManager';
import { onMounted } from 'vue';

onMounted(() => {
    SEOManager.setAllMeta();
});

</script>

<template>
    <h1>Home page</h1>
    <el-button type="primary">Primary</el-button>
</template>

<style setup>

</style>