export const API_URL = process.env.VUE_APP_API_URL;
export const allowedRoles = ["support", "admin", "mod", "test", "visitor"];
export const min_password_length = 4;

export const defaultLogo = new URL('@/assets/img/default/logo.png', import.meta.url).href;
export const defaultCover = new URL('@/assets/img/default/coverImage.jpg', import.meta.url).href;
export const defaultIco = new URL('@/assets/img/default/ico.ico', import.meta.url).href;
export const defaultImg = new URL('@/assets/img/default/defaultImg.png', import.meta.url).href;
export const defaultImgUser = new URL('@/assets/img/default/user.jpg', import.meta.url).href;

export const systemImagesUrl = `${API_URL}/uploads/systemImages`;
export const userPhotoUrl = `${systemImagesUrl}/images/users`