import { showError } from "@/helpers/showError";
import axios from "axios";

class SettingsService {
    async getAll() {
        try {
            return axios.get('/settings');
        } catch (error) {
            showError(error);
            throw error;
        }
    }
}

export default new SettingsService();